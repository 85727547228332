.services-area {
    background-color: #fff;
    padding: 30px 30px 40px 30px;
    border-radius: 20px;
}

.single-service-item img {
    height: 80px;
    margin-bottom: 22px;
}

.single-service-item {
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 5px;
}

.single-service-item h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #e82a5e;
}


/*--- Responsive CSS ---*/


    @media (max-width: 767.98px) { 
        .services-area{
            padding: 25px 15px;
        }
         
        .single-service-item h3 {
            font-size: 18px;
        }
    }
    