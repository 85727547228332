.pwa-wrapper{
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    background-size: inherit;
    height: 100vh;
    padding: 0 50px;
}

.section-top {
    padding: 30px 30px 40px 30px;
    background-color: #fff;
}

.section-bottom {
    padding: 30px 30px 20px 30px;
    background-color: #f5f6f7;
}

h1.section-title {
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    display: inline-block;
    background: -webkit-linear-gradient(45deg, #304cfd, #e82a5e 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
    padding-left: 45px;
}

h1.section-title:before {
    content: "";
    left: 0;
    top: 50%;
    position: absolute;
    width: 40px;
    height: 2px;
    margin-top: -1px;
    background-color: #4e48e7;
}

.boxed-btn {
    background-color: #304CFD;
    border-radius: 1.25rem;
    border: 0;
    box-shadow: 0px 8px 16px rgba(48, 76, 253, 0.15), 0px 2px 4px rgba(48, 76, 253, 0.15);
    color: #fff !important;
    line-height: inherit;
    padding: 11px 35px;
    position: relative;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    display: inline-block;
}

.boxed-btn i {
    padding-right: 8px;
}

.boxed-btn:hover {
    background-color: #e82a5e;
    box-shadow: 0px 8px 16px rgba(232, 42, 93, 0.15), 0px 2px 4px rgba(232, 42, 93, 0.15);
}

.section-shadow{
    box-shadow: 0px 40px 50px rgba(103, 118, 128, 0.1) !important;
    border-radius: 20px;
    height: 85vh;
    overflow-x: scroll;
}

.social-links a {
    display: inline-block;
    background-color: #44556c;
    color: #fff;
    margin: 2.5px;
    height: 30px;
    width: 30px;
    line-height: 32px;
    border-radius: 3px;
    -webkit-transition: .4s;
    transition: .4s;
    text-align: center;
}

.social-links a:hover{
    background-color: #000;
}

.mobile-menu-warapper{
    display: none;
}

/*--- Slick CSS ---*/
ul.slick-dots li div {
    height: 11px;
    width: 11px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #44556c;
    margin: 3px;
    -webkit-transition: .4s;
    transition: .4s;
}

ul.slick-dots li {
    display: inline-block;
}

ul.slick-dots {
    list-style: none;
    text-align: center;
    margin-top: 30px;
}

ul.slick-dots li.slick-active div {
    background-color: #304cfd;
    width: 35px;
    border-radius: 10px;
}


.slick-arrow {
    width: 30px;
    height: 30px;
    background-color: #304cfd;
    color: #fff;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: -15px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-transition: .4s;
    transition: .4s;
}

.slick-arrow.slick-prev {
    right: -15px;
    left: auto;
}

.slick-arrow:hover {
    background-color: #0d78a3;
}


/*--- Responsive CSS ---*/


    @media (max-width: 767.98px) { 
        .pwa-wrapper {
            padding: 0;
        }
        
        .section-shadow {
            height: auto;
            overflow-x: auto;
            margin-bottom: 25px;
        }

        .section-top, .section-bottom {
            padding: 25px 15px;
        }

        .boxed-btn {
            padding: 10px 25px;
            font-size: 15px;
        }
    }
    

    @media (min-width: 768px) and (max-width: 991.98px) { 
        .pwa-wrapper {
            padding: 0 20px;
        }    
     }
    
